<template>
  <div class="campus-edit-page">
    <el-breadcrumb separator-class="el-icon-right">
      <el-breadcrumb-item :to="{ path: '/admin/dashboard' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>内容管理</el-breadcrumb-item>
      <el-breadcrumb-item>校区管理</el-breadcrumb-item>
      <el-breadcrumb-item>编辑校区</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="page-content">
      <el-form :model="pageForm" :rules="pageRules" ref="pageRef" label-width="auto" class="page-form">
        <el-form-item label="校区名称" prop="title">
          <el-input v-model="pageForm.title"></el-input>
        </el-form-item>
        <el-form-item label="logo" prop="logo">
          <upload :url="pageForm.logo" :filed="'logo'" :max-num="1" @checked-files="checkedFile" @del-select="delFile"></upload>
        </el-form-item>
        <el-form-item label="栏目背景图是否随点击更换" prop="is_change">
          <el-radio-group v-model="pageForm.is_change">
            <el-radio :label="1">固定背景图</el-radio>
            <el-radio :label="2">跟随点击变换</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="背景图" prop="background">
          <upload :url="pageForm.background" :filed="'background'" :max-num="1" @checked-files="checkedFile" @del-select="delFile"></upload>
        </el-form-item>
        <el-form-item label="首页显示模块数量" prop="block_num">
          <el-input-number size="small" v-model="pageForm.block_num" :step="1" :min="1" :max="5"></el-input-number>
        </el-form-item>
        <el-form-item label="全局水印文字" prop="watermark">
          <el-input v-model="pageForm.watermark"></el-input>
        </el-form-item>
        <el-form-item label="校区状态" prop="status">
          <el-select v-model="pageForm.status" placeholder="请选校区状态">
            <el-option label="正常" :value="1"></el-option>
            <el-option label="关闭" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submit">立即创建</el-button>
          <el-button @click="backPage">返 回</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import Upload from '../common/Upload'
export default {
  name: 'CampusEdit',
  components: {
    Upload
  },
  data () {
    return {
      pageForm: {
        m_id: '',
        title: '',
        logo: '',
        is_change: 2,
        background: '',
        block_num: 4,
        watermark: '',
        status: 1
      },
      pageRules: {
        title: [
          { required: true, message: '请填写校区名称', trigger: 'blur' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ],
        logo: [
          { required: true, message: '请上传校区logo', trigger: 'blur' }
        ],
        background: [
          { required: true, message: '请上传校区背景图', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    this.getPageData()
  },
  methods: {
    // 获取校区信息
    async getPageData () {
      const { data: res } = await this.$http.get('/campus', { params: { mid: this.$route.params.mid } })
      if (res.code === 200) {
        this.pageForm = res.data
      } else {
        this.$message.error(res.msg)
      }
    },
    submit () {
      this.$refs.pageRef.validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.put('/campus', this.pageForm)
          if (res.code === 200) {
            this.$message.success(res.msg)
            await this.$router.push('/admin/content/campus')
          } else {
            this.$message.error(res.msg)
          }
        } else {
          this.$message.error('表单验证失败')
          return false
        }
      })
    },
    backPage () {
      this.$router.push('/admin/content/campus')
    },
    // 上传组件返回值
    checkedFile (data) {
      this.pageForm[data.filed] = data.url
    },
    // 删除已选择文件
    delFile (field, item, pattern) {
      // 单文件删除
      if (pattern === 'single') {
        this.pageForm[field] = ''
      } else {
        // 多文件删除
        const checkedIndex = this.pageForm[field].findIndex(fileItem => fileItem === item)
        // 如果已包含了该文件则移除文件 和 checked 样式
        this.pageForm[field].splice(checkedIndex, 1)
      }
    }
  }
}
</script>

<style scoped>
.page-content{
  width: 60%;
  margin-left: auto;
  margin-right: auto;
}
</style>
